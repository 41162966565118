<template>
  <div class="content-manage">
    <div class="btn-box">
      <span>集采须知配置</span>
      <fontButton @click="save">保存</fontButton>
    </div>
    <div class="edit-box" id="edit-box"></div>
  </div>
</template>
<script>
import E from 'wangeditor' 
import {ajax_post_xuzhi, ajax_get_xuzhi, fileUpload} from  "../../../api/index"
export default {
  name: 'contentManage',
  data () {
    return {
      editor: null,
      editData:'<p style="text-align:left;">【通知】<b>wangEditor 新版本 V5</b>&nbsp;已公开测试，可查阅&nbsp;<a href="https://www.wangeditor.com/v5/">V5 文档</a>。</p><h1 style="text-align:left;">为何选择 wangEditor</h1><ul style="text-align:left;"><li>简洁、轻量级、<a href="https://www.wangeditor.com/doc/">文档</a>齐全</li><li>万星项目&nbsp;<a href="https://github.com/wangeditor-team/wangEditor/releases">Github Star 1w+</a></li><li>npm 周下载量 1w+</li><li>CDN 月下载量百万+（来自&nbsp;<a href="https://www.jsdelivr.com/package/npm/wangeditor">jsdelivr</a>）</li><li>QQ 群及时答疑</li><li><a href="https://www.wangeditor.com/doc/#%E5%BC%80%E5%8F%91%E4%BA%BA%E5%91%98">开源团队</a>维护，非个人单兵作战</li></ul><h1 style="text-align:left;">初见</h1><p style="text-align:left;">npm 安装&nbsp;<code>npm i wangeditor --save</code>&nbsp;，几行代码即可创建一个编辑器。</p>'
    }
  },
  created(){
    
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      ajax_get_xuzhi({configName:"instructions"}).then(res=>{
        if(res.code == 200){
          this.editData = res.result.configValue;
          this.editInit();
        }
      }).catch(err=>{

      })
    },
    editInit(){
      this.editor = new E('#edit-box')
      this.editor.config.uploadImgServer = '/api/upload/imageUpload'
      this.editor.config.customUploadImg = this.uploadImg;
      // this.editor.config.colors = [
      //     '#000000',
      //     '#eeece0',
      //     '#1c487f',
      //      '#4d80bf'
      // ]
      this.editor.create()
      this.editor.txt.html(this.editData)
    },
    uploadImg(resultFiles, insertImgFn){
      const serverUrl =  "https://wrjc.wanrongly.com/"
      console.log(resultFiles,"resultFiles")
      let data = new FormData();
      data.append('file',resultFiles[0])
      fileUpload(data).then(res=>{
        if(res.code == 200){
          let imgUrl = [serverUrl + res.result]
          console.log(imgUrl)
          insertImgFn(imgUrl)
        }
      }).catch(err=>{
        console.log("ajax_err,",err)
      })
         // resultFiles 是 input 中选中的文件列表
         // insertImgFn 是获取图片 url 后，插入到编辑器的方法

         // 上传图片，返回结果，将图片插入到编辑器中
        //  insertImgFn(imgUrl)
    },
    save(){
      let html = this.editor.txt.html();
      console.log(html,"html")
      ajax_post_xuzhi({configName:"instructions",configValue:html}).then(res=>{
        if(res.code == 200){
          this.$message.success("操作成功！")
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content-manage {
  width: 100%;
  height: 100%;
  .btn-box{
    height: 40px;
    width: 100%;
    display:flex;
    justify-content:space-between;
    align-items: center;
  }
  .edit-box{
    width: 100%;
    height: calc(100% - 40px);
  }
}
</style>
